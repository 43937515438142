<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <DarkToggler class="block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.username }}
            </p>
            <span class="user-status">{{ user.email }}</span>
          </div>
          <b-avatar
            :src="user.avatar ? avatarUrl : null"
            size="40"
            variant="light-primary"
            class="badge-minimal"
            badge-variant="success"
            badge
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="
            () => {
              $router.push({ name: 'profile' })
            }
          "
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>
            {{ $t('nav.profile') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="
            () => {
              $cookies.remove('JWToken')
              $router.push({ name: 'sign_in' })
            }
          "
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t('nav.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { computed, ref, onMounted, watch } from '@vue/composition-api'
import DarkToggler from '@/views/components/navbar/DarkToggler.vue'
import Locale from '@/views/components/navbar/Locale.vue'
import store from '@/store'
import { getImage } from '@/api/attachments'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const user = computed(() => store.getters['usersStore/user'])
    const avatarUrl = ref('')

    watch(
      () => user.value,
      async () => {
        if (user.value.avatar) {
          avatarUrl.value = await getImage({ filename: user.value.avatar })
        }
      }
    )

    onMounted(async () => {
      if (user.value.avatar) {
        avatarUrl.value = await getImage({ filename: user.value.avatar })
      }
    })

    return {
      user,
      avatarUrl,
    }
  },
}
</script>

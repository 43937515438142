export default [
  {
    title: 'nav.home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'nav.customers',
    route: 'customers',
    icon: 'UserIcon',
  },
  {
    title: 'nav.documents',
    route: 'documents',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'nav.registerAgreements',
        route: { name: 'documentsWithType', params: { type: 'agreement' } },
        icon: 'FileTextIcon',
      },
      {
        title: 'nav.registerChecks',
        route: { name: 'documentsWithType', params: { type: 'check' } },
        icon: 'FileTextIcon',
      },
      {
        title: 'nav.registerReceipts',
        route: { name: 'documentsWithType', params: { type: 'receipt' } },
        icon: 'FileTextIcon',
      },
      {
        title: 'nav.registerDocuments',
        route: 'documents',
        icon: 'FileTextIcon',
      },
    ],
  },
  {
    title: 'nav.templates',
    route: 'templates',
    icon: 'EditIcon',
  },
  {
    title: 'nav.types',
    route: 'types',
    icon: 'TypeIcon',
  },
  {
    title: 'nav.journal',
    route: 'journal',
    icon: 'CalendarIcon',
  },
  {
    title: 'nav.roles',
    route: 'roles',
    icon: 'GithubIcon',
    adminOnly: true,
  },
]

<template>
  <layout-vertical>
    <router-view />
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <template #breadcrumb>
      <app-breadcrumb />
    </template>
    <template #footer>
      <span />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical'
import Navbar from '../components/Navbar'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb'

export default {
  components: {
    AppBreadcrumb,
    LayoutVertical,
    Navbar,
  },
}
</script>
